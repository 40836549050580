import {getSubscriptionData, getUser, setSyncStatus} from "../store/reducers/userSlice";
import fetchAllData from "./fetchAllData";
import {showNotification} from "../store/reducers/notificationSlice";
import store from "../store/store";
import {openShareLabelsDialog} from "../store/reducers/dialogSlice";
import {updateSharingStatus} from "../store/reducers/groupsSlice";

export default function sockets(socketToken) {
    const socket = new WebSocket(`wss://app.collaborativecontacts.com/sockets/${socketToken}`);
    socket.addEventListener("open", (event) => {
        socket.send("Connection established");
    });
    socket.addEventListener("message", async (event) => {
        try {
            const data = JSON.parse(event.data);
            if (data.action === 'SYNC_FINISHED') {
                store.dispatch(setSyncStatus('SYNC_FINISHED'));
                await fetchAllData(store.dispatch);
                store.dispatch(
                    showNotification({
                        type: 'success',
                        message: "The labels have been updated successfully.",
                    })
                );
            }
            if (data.action === 'SUBSCRIPTION_CREATED') {
                await store.dispatch(getSubscriptionData());
                store.dispatch(
                    showNotification({
                        type: 'success',
                        message: "The subscription created.",
                    })
                );
            }
            if (data.action === 'SUBSCRIPTION_UPDATED') {
                await store.dispatch(getSubscriptionData());
                store.dispatch(
                    showNotification({
                        type: 'success',
                        message: "The subscription updated.",
                    })
                );
            }
            if (data.action === 'USER_INFO_UPDATED') {
                store.dispatch(openShareLabelsDialog(false));
                await fetchAllData(store.dispatch);
            }
            if (data.action === 'SHARING_UPDATE') {
                await store.dispatch(updateSharingStatus({data: data.payload}));
                store.dispatch(getSubscriptionData());
            }
        } catch (error) {
            if (!(error instanceof SyntaxError)) {
                store.dispatch(
                    showNotification({
                        type: 'error',
                        message: "An error occurred!",
                    })
                );
            }
        }
    });
    return socket;
}

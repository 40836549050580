import * as React from "react";
import {Box, Button, Stack, SvgIcon, Typography} from "@mui/material";

// project import
import {PhonelinkSetupOutlined} from "@mui/icons-material";
import {Link as RouterLink} from "react-router-dom";

export default function MobilePage() {
    return (
        <Stack height="100vh" spacing={1} sx={{backgroundColor: 'F5F5F5'}}>
            <RouterLink to={process.env.REACT_APP_PUBLIC_URL} style={{textDecoration: 'none'}}>
                <Stack ml={1} direction={'row'} spacing={0}
                       sx={{width: 278, height: 64, display: 'flex', alignItems: 'center'}}>
                    <Box
                        component="img"
                        sx={{
                            width: 40,
                            height: 40,
                        }}
                        alt="Logo"
                        src={`${process.env.REACT_APP_PUBLIC_URL}assets/logo.png`}
                        srcSet={`${process.env.REACT_APP_PUBLIC_URL}assets/logo2x.png 2x`}
                        ml={1}
                    />
                    <Box
                        ml={'2px'}
                        sx={{
                            minWidth: 209,
                            maxWidth: 209,
                        }}>
                        <Typography variant={'subtitle2'} color={'#5F6368'}>
                            Collaborative Contacts
                        </Typography>
                    </Box>
                </Stack>
            </RouterLink>
            <Stack height="100%" alignItems="center" justifyContent="center" spacing={3} minWidth={390}>
                <PhonelinkSetupOutlined sx={{width: 48, height: 48, color: 'rgba(0, 0, 0, 0.54)'}}/>
                <Box
                    sx={{
                        display: 'inline-flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px',
                        width: 306,
                    }}
                >
                    <Typography
                        sx={{
                            color: 'rgba(60, 64, 67, 1)',
                            fontFamily: 'Open Sans',
                            fontSize: 20,
                            fontWeight: '600',
                            lineHeight: '28px',
                            letterSpacing: '-0.02em',
                        }}
                    >
                        Our mobile version is on the way
                    </Typography>
                    <Typography
                        sx={{
                            color: 'rgba(0, 0, 0, 0.60)',
                            fontFamily: 'Open Sans',
                            fontSize: 16,
                            fontWeight: '400',
                            lineHeight: '24px',
                            letterSpacing: '-0.02em',
                            textAlign: 'center',
                        }}
                    >
                        Please use the app on a desktop for the best experience. Thank you!
                    </Typography>
                </Box>
            </Stack>
        </Stack>
    )
}

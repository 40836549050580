import {useEffect} from "react";
import {
    CssBaseline,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";

// project import
import ThemeProvider from './theme';
import loginRedirect from "./services/loginRedirect";
import Router from "./routes";
import fetchAllData from "./api/fetchAllData";
import store from "./store/store";
import NotificationBar from "./components/Alerts/NotificationBar";
import sockets from "./api/sockets";
import {setSelectedContacts} from "./store/reducers/contactsSlice";



const GoogleSignIn = ({ onSuccess, onError }) => {
    useEffect(() => {
      console.log('GoogleSignIn component initializing');
      
      // Initialize Google Sign-In
      const initializeGsi = () => {
        if (!window.google) {
          console.log('Google API not found');
          return;
        }
        
        console.log('Initializing Google Sign-In API');
        window.google.accounts.id.initialize({
          client_id: "211122818894-2k3ljehkcgat38t44fro33u432cp2se6.apps.googleusercontent.com",
          auto_select: true, // Автоматический выбор аккаунта
          context: 'signin',
          ux_mode: 'popup',
          callback: (response) => {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = 'https://app.collaborativecontacts.com/auth_with_google';
            
            const credentialInput = document.createElement('input');
            credentialInput.type = 'hidden';
            credentialInput.name = 'credential';
            credentialInput.value = response.credential;
            
            form.appendChild(credentialInput);
            document.body.appendChild(form);
            form.submit();
          },
          itp_support: true,
          prompt_parent_id: 'g_id_onload' // Добавляем ID элемента для автоматического показа
        });

        // Добавляем невидимый элемент для автологина
        const autoSignInDiv = document.createElement('div');
        autoSignInDiv.id = 'g_id_onload';
        autoSignInDiv.style.display = 'none';
        document.body.appendChild(autoSignInDiv);

  
        console.log('Rendering Google Sign-In button');
        // Display button
        window.google.accounts.id.renderButton(
          document.getElementById("google-signin-button"), 
          {
            type: "standard",
            shape: "rectangular", 
            theme: "outline",
            text: "signin_with",
            size: "large",
            logo_alignment: "left",
          }
        );


        // Автоматично показуємо попап для входу
        window.google.accounts.id.prompt();

      };
  
      // Handler for successful authorization
      const handleCredentialResponse = (response) => {
        console.log('Received response from Google Sign-In:', response);
        if (onSuccess) {
          console.log('Calling onSuccess callback');
          onSuccess(response);
        }
      };
  
      console.log('Loading Google Identity Services script');
      // Load Google Identity Services script
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log('Google Identity Services script loaded successfully');
        initializeGsi();
      };
      script.onerror = () => {
        console.error('Failed to load Google Identity Services');
        if (onError) {
          onError(new Error("Failed to load Google Identity Services"));
        }
      };
      document.head.appendChild(script);
  
      // Cleanup on unmount
      return () => {
        console.log('Cleaning up GoogleSignIn component');
        document.head.removeChild(script);
      };
    }, [onSuccess, onError]);
  
    return <div id="google-signin-button"/>;
  };

export default function App() {
    const dispatch = useDispatch();
    const needLogin = useSelector((state) => state.user.needLogin);
    const currentGroup = useSelector((state) => state.groups.currentGroup);
    const contacts = useSelector((state) => state.contacts.contacts);
    useEffect(() => {
        async function fetchData() {
            const result = await fetchAllData(dispatch);
            const getUserResult = result.find(item => item.type === 'user/getUser/fulfilled');
            if (!getUserResult || getUserResult.payload.need_login) return null;
            const socket = sockets(getUserResult.payload.me.socket_token)
            return () => socket.close();
        }

        fetchData();
    }, [dispatch]);
    useEffect(() => {
        async function updateSelectedContacts() {
            if (currentGroup) {
                const resourceSet = new Set(currentGroup.contacts_resources);
                const filteredContacts = contacts.filter((user) => resourceSet.has(user.id));
                store.dispatch(setSelectedContacts({selectedContacts: filteredContacts}));
            }
        }

        updateSelectedContacts();
    }, [currentGroup, contacts, dispatch]);


    let googleSignIn = null;

    if (needLogin) {
        googleSignIn = <GoogleSignIn/>
    }

    return (
        <ThemeProvider>
            <CssBaseline/>
            <NotificationBar/>
            <Router/>
            {googleSignIn}
        </ThemeProvider>
    );
}
